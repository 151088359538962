import React from 'react'
import { IListingCounterCity } from '../../Index'
import { generateUrl } from '../../../../utils/Helper'
import LegacyCarousel from '../../../LegacyCarousel'
import { Project } from '../../../../pages'
import Image from 'next/image'

type Props = {
    data: Project[]
    listingCounterCity: IListingCounterCity
}

const ExploreCities = (props: Props) => {
    const renderCityCard = (name: string, image: string, url: string, count: number, size: 0 | 1 | 2 | 3) => (
        <div className={`relative w-full overflow-hidden rounded ${size === 0 || size === 2 ? 'pt-full' : size === 1 ? 'pt-50% col-span-2' : 'col-span-2 row-span-2 pt-full'}`}
        >
            <a href={url}
            >
                <div
                    className="absolute inset-0 h-full w-full px-4 py-4 flex flex-col items-start text-white"
                    style={{
                        backgroundImage: `linear-gradient(180deg, rgba(1, 1, 1, 0.8) 0%, rgba(1, 1, 1, 0) 60.89%)`,
                    }}
                >
                    <div className={`inset-0 w-full h-full absolute`} style={{ zIndex: -1 }}>
                        <Image src={image} alt={name} layout='fill' sizes='100vw' objectFit='cover' loading='lazy' />
                    </div>
                    <span className="text-2xl font-semibold leading-none mb-2">
                        {name}
                    </span>
                    {
                        size === 3 &&
                        <span className="font-semibold leading-none">
                            {count} Listings
                        </span>
                    }
                </div>
            </a>
        </div>
    )

    const renderCityCardMobile = (name: string, image: string, url: string, count: number) => (
        <a href={url} className={`w-full h-full grid grid-cols-1 grid-rows-1 flex-shrink-0`}>
            <div className="relative overflow-hidden rounded">
                <div
                    className=" inset-0 h-full w-full px-18px py-4 flex flex-col items-start text-white"
                    style={{
                        // backgroundImage: `linear-gradient(360deg, rgba(1, 1, 1, 0.8) 0%, rgba(1, 1, 1, 0) 60.89%)')`,
                        backgroundImage: 'linear-gradient(180deg, rgba(1, 1, 1, 0.8) 0%, rgba(1, 1, 1, 0) 60.89%)'
                    }}
                >
                    <div className={`inset-0 w-full h-full absolute`} style={{ zIndex: -1 }}>
                        {/* <img src={image} alt={name} loading='lazy' className='w-full h-full object-cover' /> */}
                        <Image src={image} alt={name} layout='fill' sizes='100vw' objectFit='cover' loading='lazy' />
                    </div>
                    <span className="text-xl font-semibold leading-none mb-2">
                        {name}
                    </span>
                    <span className="text-sm font-semibold leading-none">
                        {count} Listings
                    </span>
                </div>
            </div>
        </a>
    )
    return (
        <div className={`pb-6 container lg:pb-8 mb-8`}>
            <h2 className="text-black-#3D3D3D text-lg lg:text-32px font-bold leading-snug mb-0 lg:mb-1.5">Explore Cities</h2>
            <p className="text-xs lg:text-base text-gray-#8F8F8F mb-6 lg:mb-8">
                Find your dream property to live or invest in right in your neighbourhood!
            </p>
            <div className="hidden lg:grid grid-cols-6 gap-2">
                {renderCityCard('BSD City', 'https://ecatalog.sinarmasland.com/assets/homepage/bsd-card.png', generateUrl('en', '/city/bsd-city'), props.listingCounterCity.bsd, 3)}
                {renderCityCard('DKI Jakarta', '/static/images/cities/jakarta.png', generateUrl('en', '/city/jakarta'), props.listingCounterCity.jakarta, 0)}
                {renderCityCard('Cikarang', '/static/images/cities/cikarang.png', generateUrl('en', '/city/cikarang'), props.listingCounterCity.cikarang, 0)}
                {renderCityCard('Cibubur', '/static/images/cities/bogor.png', generateUrl('en', '/city/cibubur'), props.listingCounterCity.cibubur, 2)}
                {renderCityCard('Balikpapan', '/static/images/cities/balikpapan.png', generateUrl('en', '/city/balikpapan'), props.listingCounterCity.balikpapan, 2)}
                {renderCityCard('Tangerang', '/static/images/cities/tangerang.png', generateUrl('en', '/city/tangerang'), props.listingCounterCity.tangerang, 1)}
                {renderCityCard('Batam', '/static/images/cities/batam.png', generateUrl('en', '/city/batam'), props.listingCounterCity.batam, 0)}
                {renderCityCard('Surabaya', '/static/images/cities/surabaya.png', generateUrl('en', '/city/surabaya'), props.listingCounterCity.surabaya, 0)}
            </div>
            <div className="lg:hidden -mx-5px">
                <LegacyCarousel
                    show={1}
                    withIndicator
                    isRepeatedProps
                    indicatorSpacing="pt-5"
                >
                    <div className="w-full md:w-1/2 lg:w-1/2 flex-shrink-0 grid grid-cols-2 gap-3 px-1.5">
                        <div className={`col-span-2 w-full h-full relative rounded overflow-hidden`} style={{ height: 180 }}>
                            {renderCityCardMobile('BSD City', 'https://ecatalog.sinarmasland.com/assets/homepage/bsd-card.png', generateUrl('en', '/project/bsd-city'), props.listingCounterCity.bsd)}
                        </div>
                        <div className={`col-span-2 w-full h-full relative rounded overflow-hidden`} style={{ height: 180 }}>
                            {renderCityCardMobile('Tangerang', '/static/images/cities/tangerang.png', generateUrl('en', '/search?location_type=city&location_id=1&location_text=Tangerang&property_type=All'), props.listingCounterCity.tangerang)}
                        </div>
                        <div className={`w-full h-full relative rounded overflow-hidden`} style={{ height: 180 }}>
                            {renderCityCardMobile('DKI Jakarta', '/static/images/cities/jakarta.png', generateUrl('en', '/search?location_type=province&location_id=6&location_text=DKI%20Jakarta&property_type=All'), props.listingCounterCity.jakarta)}
                        </div>
                        <div className={`w-full h-full relative rounded overflow-hidden`} style={{ height: 180 }}>
                            {renderCityCardMobile('Cikarang', '/static/images/cities/cikarang.png', generateUrl('en', '/search?location_type=city&location_id=92&location_text=Cikarang&property_type=All'), props.listingCounterCity.cikarang)}
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/2 flex-shrink-0 grid grid-cols-2 gap-3 px-1.5">
                        <div className={`w-full h-full relative rounded overflow-hidden`}>
                            {renderCityCardMobile('Cibubur', '/static/images/cities/bogor.png', generateUrl('en', '/search?location_type=city&location_id=13&location_text=Cibubur&property_type=All'), props.listingCounterCity.cibubur)}
                        </div>
                        <div className={`w-full h-full relative rounded overflow-hidden`}>
                            {renderCityCardMobile('Balikpapan', '/static/images/cities/balikpapan.png', generateUrl('en', '/search?location_type=city&location_id=207&location_text=Balikpapan&property_type=All'), props.listingCounterCity.balikpapan)}
                        </div>
                        <div className={`w-full h-full relative rounded overflow-hidden`}>
                            {renderCityCardMobile('Batam', '/static/images/cities/batam.png', generateUrl('en', '/search?location_type=city&location_id=18&location_text=Batam&property_type=All'), props.listingCounterCity.batam)}
                        </div>
                        <div className={`w-full h-full relative rounded overflow-hidden`}>
                            {renderCityCardMobile('Surabaya', '/static/images/cities/surabaya.png', generateUrl('en', '/search?location_type=city&location_id=9&location_text=Surabaya&property_type=All'), props.listingCounterCity.surabaya)}
                        </div>
                    </div>
                </LegacyCarousel>
            </div>
        </div>
    )
}

export default React.memo(ExploreCities) 